(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('TariffOptionsCtrl', [
        '$scope',
        '$preloaded',
        'TariffSelectedExchange',
        function ($scope, $preloaded, selected) {
            $scope.options = $preloaded.get('tariffOptions');
            $scope.selected = selected;
        }
    ]);
})(window, window.app, window._, window.debug);
