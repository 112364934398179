(function (window, app, _, debug, undefined) {
    'use strict';
    app
        .config(function($interpolateProvider, $httpProvider){
            $httpProvider.defaults.xsrfCookieName = 'csrftoken';
            $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
            $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        })
        .run(function($templateCache) {
            $templateCache.put('wf/field-text.html', '<label for="text[[id]]">\n    <div class="form-compact-row-col form-compact-row-label">\n        <span data-ng-bind-html="options.label+\':\'"></span>\n        <span data-ng-show="$parent.options.showRequire && options.required" class="form-required"> *</span>\n    </div><div class="form-compact-row-col form-compact-row-input">\n        <input type="text" formly-name="name" class="form-input"\n               name="[[field]]" id="text[[id]]" placeholder="[[ options.placeholder ]]" \n               data-ng-required="options.required" data-ng-disabled="options.disabled"\n               data-ng-pattern="options.pattern" data-ng-model="value">\n    </div>\n</label>');
            $templateCache.put('wf/field-email.html', '<label for="email[[id]]">\n    <div class="form-compact-row-col form-compact-row-label">\n        <span data-ng-bind="options.label+\':\'"></span>\n        <span data-ng-show="$parent.options.showRequire && options.required" class="form-required"> *</span>\n    </div><div class="form-compact-row-col form-compact-row-input">\n        <input type="email" formly-name="name" class="form-input" \n               name="[[field]]" id="email[[id]]" placeholder="[[options.placeholder]]" \n               data-ng-required="options.required" data-ng-pattern="options.pattern" \n               data-ng-disabled="options.disabled" data-ng-model="value">\n    </div>\n</label>');
            $templateCache.put('wf/field-tel.html', '<label for="tel[[id]]">\n    <div class="form-compact-row-col form-compact-row-label">\n        <span data-ng-bind="options.label+\':\'"></span>\n        <span data-ng-show="$parent.options.showRequire && options.required" class="form-required"> *</span>\n    </div><div class="form-compact-row-col form-compact-row-input">\n        <input ui-mask="+7 (999) 999-99-99" formly-name="name" type="tel" \n               class=form-input id="tel[[id]]" data-ng-required="options.required" \n               data-ng-disabled="options.disabled" data-ng-model="value">\n    </div>\n</label>');
            $templateCache.put('wf/field-textarea.html', '<label for="tel[[id]]">\n    <div class="form-compact-row-col form-compact-row-label">\n        <span data-ng-bind="options.label+\':\'"></span>\n        <span data-ng-show="$parent.options.showRequire && options.required" class="form-required"> *</span>\n    </div><div class="form-compact-row-col form-compact-row-input">\n        <textarea placeholder="[[options.placeholder]]" formly-name="name" \n                  class="form-input form-textarea" id="textarea[[id]]" \n                  data-ng-required="options.required" data-ng-disabled="options.disabled" \n                  data-ng-pattern="options.pattern" data-ng-model="value"></textarea>\n    </div>\n</label>');
            $templateCache.put('directives/formly-form.html', '<form name="formInstance" class="form" data-ng-class="options.style.form" novalidate role="form">\n    <ul class="form-compact" data-ng-class="options.style.list">\n        <li class="form-compact-row form-compact-row-single" data-ng-class="options.style.itemTitle">\n            <h2 data-ng-bind-html="options.title"></h2>\n        </li>\n        <li class="form-compact-row" data-ng-class="options.style.item" \n            formly-field data-ng-repeat="field in fields" options="field" \n            field-name="field.key" form-value="result[field.key||$index]" \n            form-id="options.formId" index="$index">\n        </li>\n        <li class="form-compact-row form-compact-row-single" data-ng-class="options.style.itemFooter">\n            <button type="submit" class="button button-fullwidth button-pink"\n                    data-ng-class="options.style.submit"\n                    data-ng-hide="options.hideSubmit" \n                    data-ng-bind="options.submitCopy"\n                    data-ng-disabled="formInstance.$invalid" >\n            </button>\n        </li>\n    </ul>\n</form>');
        })
        .config(function(formlyTemplateProvider){
            formlyTemplateProvider.setTemplateUrl({
                text: 'wf/field-text.html',
                email: 'wf/field-email.html',
                tel: 'wf/field-tel.html',
                textarea: 'wf/field-textarea.html'
            });
        });
})(window, window.app, window._, window.debug);
