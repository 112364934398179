(function (window, app) {
    'use strict';
    app.directive('phoneset', function ($animate) {
        return {
            link : function (scope, element, attrs) {
                var oldStr = element.text(),
                    newStr = '<span>';

                newStr += oldStr.split('').join('</span><span>') + '</span>';
                element.html(newStr);
            }
        };
    });
})(window, window.app);
