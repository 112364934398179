(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('SendEmailModalCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        'SendEmailModal',
        '$preloaded',
        'formlyTemplate',
        'FormHelper',
        function ($scope, $rootScope, $timeout, modal, $preloaded, formlyTemplate, formHelper) {
            $scope.form = $preloaded.getWithRegExp('sendEmailForm');
            $scope.closeModal = modal.deactivate;
            $scope.modalActive = modal.active;

            formlyTemplate.setTemplateUrl({
                email: 'payment/templates/send_email_field-email.html'
            });

            $scope.form.data = {};

            if ($scope.form.options) {
                $scope.form.options.formId = 'sendEmailForm';
                $scope.form.options.style = {
                    'form': ['form-feedback'],
                    'list': ['form-feedback'],
                    'itemTitle': ['form-feedback-row-single'],
                    'itemFooter': ['form-feedback-row-single']
                };
                $scope.form.options.title = 'Отправить на email';
                $scope.form.options.submitCopy = 'Отправить';
                $scope.form.options.showRequire = true;

                $scope.form.onSubmit = formHelper.defaultSubmitAction($scope.form);
                $scope.modalCloseFunc = function () {
                    return $scope.closeModal();
                };
            }

            $scope.formSendCallback = function (event, args) {
                if ($scope.form.options && args.formId != $scope.form.options.formId) {
                    return false;
                }
                return $timeout(function () {
                    $scope.modalCloseFunc();
                }, 2000);
            };
            $rootScope.$on('formSended', $scope.formSendCallback);
        }]);
})(window, window.app, window._, window.debug);
