(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('MnpCtrl', [
        '$scope',
        '$http',
        function ($scope, $http) {
            $scope.form = { data: {}, accept_terms: false, errors: null };
            $scope.loading = false;
            $scope.form.onSubmit = function(){
                $scope.form.errors = null;
                if (!$scope.form.data.name) {
                    $scope.form.errors = Object.assign({}, $scope.form.errors, {name: "Обязательное поле."});
                }
                if (!$scope.form.data.phone) {
                    $scope.form.errors = Object.assign({}, $scope.form.errors, {phone: "Обязательное поле."});
                }
                if (!$scope.form.accept_terms) {
                    $scope.form.errors = Object.assign({}, $scope.form.errors, {accept_terms: "Примите условия для отправки заявки."});
                }
                if ($scope.form.errors) {
                    return;
                }

                const data = {
                    name: $scope.form.data.name,
                    phone: $scope.form.data.phone,
                }
                if (data.phone) {
                    data.phone = data.phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
                }

                $scope.loading = true;
                $http.post('/mnp/request/', data).then(
                    function (response) {
                        $scope.loading = false;
                        $scope.success = true;
                        $scope.form.data = {};
                        $scope.form.accept_terms = false;
                        return response;
                    },
                    function (response) {
                        $scope.loading = false;
                        $scope.success = false;

                        if (response.status === 400) {
                            $scope.form.errors = {};
                            for (const key in response.data) {
                                if (Object.prototype.hasOwnProperty.call(response.data, key)) {
                                    const errors = response.data[key];
                                    $scope.form.errors[key] = errors.join(" ");
                                }
                            }
                        } else {
                            window.alert(
                                'На сервере возникла ошибка, к сожалению, сейчас невозможно отправить форму.\n' +
                                'Попробуйте отправить её вновь позднее.'
                            );
                        }

                        return response;
                    }
                );
            };
        }]);
})(window, window.app, window._, window.debug);
