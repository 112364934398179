(function (angular) {
    'use strict';

    angular
        .module('ngTouch')
        .factory('$swipe', swipe);

    function swipe() {
        return {
            bind: bind
        };

        function bind() {
            return false;
        }
    }
})(window.angular);
