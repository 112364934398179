(function (window, app, _, debug, undefined) {
    'use strict';
    app.service('SalePointsHelper', [
        function () {
            var getGeoObjects = function (objects) {
                return _.chain(objects)
                    .filter(function (item) {
                        return !_.isEmpty(item.coordinates);
                    })
                    .map(function (item) {
                        return {
                            name: item.name,
                            geometry: {
                                type: "Point",
                                coordinates: item.coordinates
                            },
                            properties: {
                                balloonContentBody: item.content
                            }
                        }
                    })
                    .value();
            },
                getMetroObjects = function (objects) {
                return _.chain(objects)
                    .filter(function (item) {
                        return item.metro && item.metro.coordX;
                    })
                    .map(function (item) {
                        return {
                            name: item.name,
                            coordX: item.metro.coordX,
                            coordY: item.metro.coordY,
                            content: item.content
                        }
                    })
                    .value();
            },
                getListObjects = function (objects) {
                return _.chain(objects)
                    .map(function (item) {
                        return {
                            name: item.name,
                            companyName: item.companyName,
                            metro: item.metro,
                            address: item.address,
                            worktime: item.worktime
                        }
                    })
                    .value();
            },
                balloonCloseAll = function (objects) {
                _.each(objects, function (object) {
                    object.balloonShow = false;
                });
            },
                balloonOpenFabric = function (objects) {
                return function (object) {
                    balloonCloseAll(objects);
                    object.balloonShow = true;
                    return object;
                }
            },
                balloonCloseFabric = function () {
                return function (object) {
                    object.balloonShow = false;
                    return object;
                }
            };
            return {
                getGeoObjects: getGeoObjects,
                getMetroObjects: getMetroObjects,
                getListObjects: getListObjects,
                balloonOpenFabric: balloonOpenFabric,
                balloonCloseFabric: balloonCloseFabric
            };
        }
    ]);
})(window, window.app, window._, window.debug);
