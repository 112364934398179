(function (window, app, _, debug, undefined) {
    'use strict';
    app.filter('selectedFilter', [ function () {
        return function (values, filters) {
            return _.filter(values, function (value) {
                return _.find(filters, function (filter, field) {
                    return !filter.length || _.indexOf(filter, value[field]) > -1;
                });
            });
        };
    }]);
})(window, window.app, window._, window.debug);
