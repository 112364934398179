(function (window, app, _, debug, undefined) {
    'use strict';
    app.service('FiltersHelper', [
        '$location',
        function ($location) {
            var getCheckboxFields = function (options) {
                return _.chain(options)
                    .filter(function (option) {
                        return option.type === 'checkbox';
                    })
                    .map(function (option) {
                        return option.field;
                    })
                    .value();
            },
                getValidSelected = function (selected, fields) {
                return _.transform(selected, function (result, item, key) {
                    result[key] = _.contains(fields, key) && _.isString(item) ? [ item ] : item;
                });
            },
                getDefinedValues = function (values) {
                return _.omit(values, function (value) {
                    var isEmptyArray = _.isArray(value) && value.length == 0;
                    return _.isUndefined(value) || isEmptyArray || value === '';
                });
            },
                clearLocationHash = function () {
                // Удаляем хэш полностью, включая "#", если браузер позволяет
                if ("history" in window && "replaceState" in window.history) {
                    history.replaceState("", document.title, location.pathname + location.search);
                }
            },
                selectedWatch = function (values) {
                var definedValues = getDefinedValues(values);
                if (_.isEmpty(definedValues)) {
                    $location.search({});
                    clearLocationHash();
                    return $location.url();
                } else {
                    return $location.search(definedValues).url();
                }
            },
                getQueryString = function (selected) {
                var str = [],
                    toStr = function(key,value){
                    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
                };
                _.each(selected, function(value, key){
                    if (_.isArray(value)){
                        _.each(value, function(item){
                            str.push(toStr(key, item));
                        });
                    } else {
                        str.push(toStr(key, value));
                    }
                });
                return str.join('&');
            };
            return {
                getCheckboxFields: getCheckboxFields,
                getValidSelected: getValidSelected,
                getDefinedValues: getDefinedValues,
                getQueryString: getQueryString,
                selectedWatch: selectedWatch
            };
        }
    ]);
})(window, window.app, window._, window.debug);
