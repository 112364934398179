(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('WhereToBuyCtrl', [
        '$scope',
        '$preloaded',
        'SalePointsHelper',
        function ($scope, $preloaded, salePointsHelper) {
            $scope.filter = $preloaded.get('whereToBuyFilter');
            $scope.salePoints = $preloaded.get('salePoints');
            $scope.companies = $preloaded.get('companies');
            $scope.geoObjects = salePointsHelper.getGeoObjects($scope.salePoints);
            $scope.metroObjects = salePointsHelper.getMetroObjects($scope.salePoints);
            $scope.metroObjects.balloonOpen = salePointsHelper.balloonOpenFabric($scope.metroObjects);
            $scope.metroObjects.balloonClose = salePointsHelper.balloonCloseFabric();
            $scope.listObjects = salePointsHelper.getListObjects($scope.salePoints);

            $scope.companiesChoiceMenu = false;
            $scope.companiesChoice = [];
            $scope.closeCompaniesChoiceMenu = function(){
                $scope.companiesChoiceMenu = false;
            };
            $scope.toggleCompaniesChoiceMenu = function(){
                $scope.companiesChoiceMenu = !$scope.companiesChoiceMenu;
            };
            $scope.toggleChoiceCompany = function(name){
                if (_.indexOf($scope.companiesChoice, name) > -1){
                    $scope.companiesChoice = _.without($scope.companiesChoice, name);
                } else {
                    $scope.companiesChoice.push(name);
                }
            };
            $scope.choiceAllCompany = function(){
                $scope.companiesChoice = [];
            };
            $scope.isCompanyChoice = function(name){
                return _.indexOf($scope.companiesChoice, name) > -1
            };

            $scope.selected = {'filter': 'list'};
        }]);
})(window, window.app, window._, window.debug);
