(function (window, app, _, debug, undefined) {
    'use strict';
    app.service('FormHelper', ['$http', '$rootScope',
        function ($http, $rootScope) {
            var defaultSubmitAction = function(form){
                return function(){
                    if (form.data.phone) {
                        form.data.phone = form.data.phone
                            .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
                    }
                    $http.post(form.options.action, form.data).then(
                        function (response) {
                            form.error = false;
                            form.success = true;
                            $rootScope.$broadcast('formSended', {formId: form.options.formId});
                            return response;
                        },
                        function (response) {
                            form.success = false;
                            form.error = response.data;
                            window.alert(
                                'На сервере возникла ошибка, ' +
                                'к сожалению, сейчас невозможно ' +
                                'отправить форму.\nПопробуйте ' +
                                'отправить её вновь позднее.'
                            );
                            return response;
                        }
                    );
                };
            },
                getLocationHash = function(){
                return location.search;
            },
                getParameterByName = function (name, hash) {
                hash = hash || getLocationHash();
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(hash);
                return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            };
            return {
                defaultSubmitAction: defaultSubmitAction,
                getLocationHash: getLocationHash,
                getParameterByName: getParameterByName
            };
        }
    ]);
})(window, window.app, window._, window.debug);
