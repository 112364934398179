(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('TariffConnectCtrl', [
        '$scope',
        '$templateCache',
        'TariffConnectModal',
        function ($scope, $templateCache, modal) {
            $templateCache.put('wf/tariff_connect_field-text.html', '<label for="text[[id]]">\n    <span data-ng-bind-html="options.label+\':\'"></span>\n    <span class="star" data-ng-show="$parent.options.showRequire && options.required"> *</span>\n    <input type="text"\n           placeholder="[[ options.placeholder ]]"\n           id="text[[id]]" \n           formly-name="name" \n           name="[[field]]"\n           data-ng-required="options.required" \n           data-ng-disabled="options.disabled"\n           data-ng-pattern="options.pattern" \n           data-ng-model="value">\n</label>\n');
            $templateCache.put('wf/tariff_connect_field-email.html', '<label for="email[[id]]">\n    <span data-ng-bind-html="options.label+\':\'"></span>\n    <span class="star" data-ng-show="$parent.options.showRequire && options.required"> *</span>\n    <input type="email"\n           placeholder="[[ options.placeholder ]]"\n           id="email[[id]]"\n           formly-name="name"\n           name="[[field]]"\n           data-ng-required="options.required"\n           data-ng-disabled="options.disabled"\n           data-ng-pattern="options.pattern"\n           data-ng-model="value">\n</label>\n');
            $templateCache.put('wf/tariff_connect_field-tel.html', '<label for="tel[[id]]">\n    <span data-ng-bind-html="options.label+\':\'"></span>\n    <span class="star" data-ng-show="$parent.options.showRequire && options.required"> *</span>\n    <input type="tel"\n           id="tel[[id]]"\n           formly-name="name"\n           name="[[field]]"\n           data-ng-required="options.required"\n           data-ng-disabled="options.disabled"\n           data-ng-pattern="options.pattern"\n           data-ng-model="value"\n           ui-mask="+7 (999) 999-99-99">\n</label>\n');
            $templateCache.put('directives/formly-form.html', '<form name="formInstance" novalidate role="form">\n    <div class="input-fields">\n        <div class="input-container"\n             data-ng-repeat="field in fields"\n             formly-field\n             options="field"\n             field-name="field.key"\n             form-value="result[field.key||$index]"\n             form-id="options.formId"\n             index="$index"\n                data-ng-class="{hidden: field.type===\'hidden\'}">\n        </div>\n    </div>\n    \n    <div class="bonus-cards">\n        <div class="bonus-card"\n             data-ng-repeat="field in options.cardFields"\n             formly-field\n             data-ng-class="field.class"\n             options="field"\n             field-name="field.key"\n             form-value="result[field.key||$index]"\n             form-id="options.formId"\n             index="$index">\n        </div>\n    </div>\n\n    <div class="btn-container">\n        <button class="smb-btn"\n                type="submit"\n                data-ng-hide="options.hideSubmit"\n                data-ng-bind="options.submitCopy"\n                data-ng-disabled="formInstance.$invalid">Подключиться</button>\n      <p ng-show="options.show_agreement" class="user_agreement-modal">*{{ options.agreement_text }} <a target="_blank" ng-href="{{options.agreement_link}}" class="user_agreement-link">{{ options.agreement_link_text }}</a></p>\n </div>\n</form>\n');

            $scope.showModal = modal.activate;
            $scope.blocks = {};
            $scope.toggle = toggle;

            function toggle(key){
                $scope.blocks[key] = !$scope.blocks[key];
            }
        }]);
})(window, window.app, window._, window.debug);
