(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('SuccessPaymentCtrl', [
        '$scope',
        '$preloaded',
        'SendEmailModal',
        function ($scope, $preloaded, modal) {
            $scope.showModal = modal.activate;

            $scope.print = function () {
                window.print();
            };
        }]);
})(window, window.app, window._, window.debug);
