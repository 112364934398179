(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('ServiceDataCtrl', [
        '$scope',
        '$preloaded',
        'ServiceSelectedExchange',
        function ($scope, $preloaded, selected) {
            $scope.services = $preloaded.get('serviceData');
            $scope.servicesDict = _.groupBy($scope.services, 'stype');
            $scope.selected = selected;
        }
    ]);
})(window, window.app, window._, window.debug);
