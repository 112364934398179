(function(window, debug, undefined) {
    'use strict';
    window.app = angular.module('MainApp', [
        'ngSanitize',
        'ngAnimate',
        'formly',
        'formly.render',
        'checklist-model',
        'ui.mask',
        'btford.modal',
        'yaMap',
        'offClick',
        'matchmedia-ng',
        'duScroll',
        'angular-carousel'
    ])
        .config([ '$interpolateProvider', function($interpolateProvider){
            $interpolateProvider.startSymbol('[[');
            $interpolateProvider.endSymbol(']]');
        }]);
})(window, window.debug);
