(function (window, app, _, debug, undefined) {
'use strict';
app.run(function($templateCache) {
  'use strict';

  $templateCache.put('directives/formly-form.html',
    "<form name=\"formInstance\" class=\"form\" ng-class=\"[[options.style.form]]\" novalidate role=\"form\">\n" +
    "    <h2 ng-bind-html=\"options.title\" ng-class=\"options.style.title\"></h2>\n" +
    "    <div ng-class=\"options.style.item\"\n" +
    "         formly-field\n" +
    "         ng-repeat=\"field in fields\"\n" +
    "         options=\"field\"\n" +
    "         field-name=\"field.key\"\n" +
    "         form-value=\"result[field.key||$index]\"\n" +
    "         form-id=\"options.formId\"\n" +
    "         index=\"$index\">\n" +
    "    </div>\n" +
    "    <div class=\"recharge__payment\">\n" +
    "        <a class=\"recharge__payment-method [[icon]]\" ng-repeat=\"icon in options.icons\"></a>\n" +
    "    </div>\n" +
    "    <button type=\"submit\"\n" +
    "            class=\"button button-fullwidth button-pink\"\n" +
    "            ng-class=\"options.style.submit\"\n" +
    "            ng-hide=\"options.hideSubmit\"\n" +
    "            ng-bind=\"options.submitCopy\"\n" +
    "            ng-disabled=\"formInstance.$invalid || options.paymentUnavailable\">\n" +
    "    </button>\n" +
    "    <p ng-show=\"options.show_agreement\" class=\"user_agreement\">*{{ options.agreement_text }} <a target=\"_blank\" ng-href=\"{{ options.agreement_link }}\" class=\"user_agreement-link\">{{ options.agreement_link_text }}</a></p>\n" +
    "    <p ng-show=\"options.show_ua\" class=\"recharge__ua\">Нажимая кнопку «продолжить» вы соглашаетесь с условиями <a href class=\"recharge__ua-link\" ng-click=\"$parent.show_rules=true;\">пользовательского соглашения</a></p>\n" +
    "</form>\n"
  );


  $templateCache.put('payment/templates/payment_field-choice.html',
    "<div class=\"recharge__payment-list\" ng-show=\"$parent.$parent.options.is_pay_available && options.key=='payment_type'\">\n" +
    "    <h2 class=\"recharge__header-2 _border-top\" ng-bind=\"options.label\">Способы оплаты:</h2>\n" +
    "    <div class=\"recharge__payment-container\" ng-repeat=\"choice in options.choices\">\n" +
    "        <label>\n" +
    "            <input type=\"radio\"\n" +
    "                   class=\"recharge__payment-input-cb\"\n" +
    "                   name=\"payment_radio\"\n" +
    "                   value=\"[[choice.0]]\"\n" +
    "                   ng-model=\"$parent.value\"\n" +
    "                   ng-required=\"parent.$parent.options.is_pay_available\">\n" +
    "            <span class=\"recharge__payment-cb\"></span>\n" +
    "            <span class=\"recharge__payment-text\">[[choice.1]]</span>\n" +
    "        </label>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('payment/templates/payment_field-number.html',
    "<label class=\"recharge__input-label\">\n" +
    "    <span class=\"recharge__input-title\" ng-bind-html=\"options.label\"></span>\n" +
    "    <input ui-mask=\"+7 (999) 999-99-99\"\n" +
    "           type=\"tel\"\n" +
    "           class=\"recharge__input-field\"\n" +
    "           id=\"tel[[id]]\"\n" +
    "           ng-pattern=\"options.pattern\"\n" +
    "           ng-required=\"options.required\"\n" +
    "           ng-disabled=\"options.disabled\"\n" +
    "           ng-model=\"value\"\n" +
    "           name=\"[[options.key]]\"\n" +
    "           required=\"required\">\n" +
    "</label>\n" +
    "<span class=\"recharge__input-message\">\n" +
    "    <span class=\"recharge__ticket-ico\" ng-class=\"$parent.$parent.options.payment_provider\"></span>\n" +
    "</span>\n"
  );


  $templateCache.put('payment/templates/payment_field-sum.html',
    "<label class=\"recharge__input-label\">\n" +
    "    <span class=\"recharge__input-title\">[[options.label]] (<span class=\"rur\">&#8399;</span>)</span>\n" +
    "    <input name=\"[[options.key]]\"\n" +
    "           ng-required=\"options.required\"\n" +
    "           ng-pattern=\"options.pattern\"\n" +
    "           ng-model=\"value\"\n" +
    "           ng-disabled=\"options.disabled\"\n" +
    "           class=\"recharge__input-field _small-width\"\n" +
    "           type=\"text\"\n" +
    "           placeholder=\"[[options.placeholder]]\"\n" +
    "           ng-max=\"options.max_value\"\n" +
    "           ng-min=\"options.min_value\">[[$parent.$parent.$parent.form.test]]\n" +
    "    <span class=\"recharge__input-message\" ng-show=\"options.max_value\">\n" +
    "        Минимум [[options.min_value]] <span class=\"rur\">&#8399;</span> ,\n" +
    "        Максимум [[options.max_value]] <span class=\"rur\">&#8399;</span>\n" +
    "        <span ng-show=\"$parent.$parent.options.is_pay_available && options.bonus_percent\">, Бонус [[options.bonus_percent]]%</span>\n" +
    "    </span>\n" +
    "    <div ng-show=\"$parent.$parent.options.is_pay_available && options.bonus_percent\"\n" +
    "         class=\"recharge__input-additional\">Сумма к зачислению (<span class=\"rur\">&#8399;</span>): [[options.total_sum]]\n" +
    "    </div>\n" +
    "</label>\n" +
    "<label ng-bind-html=\"$parent.options.belowSum\" class=\"recharge__payment-below-sum\"></label>\n"
  );


  $templateCache.put('payment/templates/send_email_field-email.html',
    "<label for=\"email[[id]]\">\n" +
    "    <div class=\"form-compact-row-col form-compact-row-label form-feedback-row-label\">\n" +
    "        <span data-ng-bind=\"options.label+':'\"></span>\n" +
    "        <span data-ng-show=\"$parent.options.showRequire && options.required\" class=\"form-required\"> *</span>\n" +
    "    </div>\n" +
    "    <div class=\"form-compact-row-col form-compact-row-input form-feedback-row-input\">\n" +
    "        <input type=\"email\"\n" +
    "               formly-name=\"name\"\n" +
    "               class=\"form-input\"\n" +
    "               name=\"[[field]]\"\n" +
    "               id=\"email[[id]]\"\n" +
    "               placeholder=\"[[options.placeholder]]\"\n" +
    "               data-ng-required=\"options.required\"\n" +
    "               data-ng-disabled=\"options.disabled\"\n" +
    "               data-ng-model=\"value\"\n" +
    "               data-ng-pattern=\"options.pattern\">\n" +
    "    </div>\n" +
    "</label>\n"
  );


  $templateCache.put('payment/templates/send_email_modal.html',
    "<div class=\"modal\" style=\"display: block;\">\n" +
    "    <div class=\"modal-mask\" data-ng-click=\"closeModal()\"></div>\n" +
    "    <div class=\"modal-cnt\">\n" +
    "        <a class=\"modal-cnt-close\" href=\"\" data-ng-click=\"closeModal()\"></a>\n" +
    "        <div class=\"block-white block-white-usual\">\n" +
    "            <formly-form data-ng-hide=\"form.success\"\n" +
    "                         result=\"form.data\"\n" +
    "                         fields=\"form.fields\"\n" +
    "                         options=\"form.options\"\n" +
    "                         data-ng-submit=\"form.onSubmit()\"></formly-form>\n" +
    "            <div class=\"form-message form-success\" data-ng-show=\"form.success\">В ближайшее время email будет отправлен</div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );
});
})(window, window.app, window._, window.debug);