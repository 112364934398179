(function (window, app) {
    'use strict';
    app.directive('filterPaneShow', function ($animate) {
        return {
            scope:{
                'show':'=filterPaneShow',
                'showCallback':'='
            },
            link: function (scope, element) {
                scope.addClassCallback = function() {
                    scope.$apply(function () { scope.showCallback(false); });
                };
                scope.$watch('show', function(newVal) {
                    if (!newVal) {
                        $animate.addClass(element, "ng-hide", scope.addClassCallback);
                    } else {
                        scope.showCallback(true);
                        $animate.removeClass(element, "ng-hide");
                    }
                });
            }
        };
    });

})(window, window.app);
