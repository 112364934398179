(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('CallmeCtrl', [
        '$scope',
        '$timeout',
        'FormHelper',
        function ($scope, $timeout, formHelper) {
            $scope.plainTel = '';
            $scope.form = { data: {}, options: { action: '/call-me/'} };
            $scope.form.onSubmit = formHelper.defaultSubmitAction($scope.form);
            $scope.timeout = timeout;
            $scope.getPlainTel = getPlainTel;

            $timeout($scope.timeout);

            function timeout(){
                $scope.plainTel = $scope.getPlainTel($scope.tel);
            }

            function getPlainTel(tel){
                return tel.replace(/[^\d\+]/g, '');
            }

        }]);
})(window, window.app, window._, window.debug);
