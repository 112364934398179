(function (angular) {
    'use strict';

    app.directive('luhn', function () {
        var directive = {
            require: 'ngModel',
            link: link,
            scope: {
                luhn: '='
            }
        };
        return directive;

        function link(scope, elem, attr, ngModel) {
            if (scope.luhn) {
                ngModel.$parsers.unshift(function (value) {
                    var valid = isLuhnValidate(value);
                    ngModel.$setValidity('luhn', valid);
                    return valid ? value : undefined;
                });

                ngModel.$formatters.unshift(function (value) {
                    ngModel.$setValidity('luhn', isLuhnValidate(value));
                    return value;
                });
            }
        }

        function isLuhnValidate(n) {
            return !/^\d+$/.test(n) || (n.split('').reduce(reduce, 0)) % 10 == 0;

            function reduce(sum, d, n) {
                return n === (n.length - 1) ? 0 : sum + parseInt((n % 2) ? d : [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][d]);
            }
        }
    });
})();
