(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('SupportCtrl', [
        '$scope',
        function ($scope) {
            $scope.answers = {};
            $scope.toggle = toggle;
            $scope.isActiveHideAll = false;
            $scope.hideAll = hideAll;

            function toggle(key){
                $scope.answers[key] = !$scope.answers[key];
                $scope.isActiveHideAll = hasOpenItems();
            }

            function hasOpenItems(){
                return _
                    .filter($scope.answers, filter)
                    .length;

                function filter(item){
                    return item;
                }
            }

            function hideAll(){
                $scope.answers = _.mapValues($scope.answers, map);
                $scope.isActiveHideAll = false;

                function map(){
                    return false;
                }
            }
        }
    ]);
})(window, window.app, window._, window.debug);
