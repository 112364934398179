(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('VacancyCtrl', [
        '$scope',
        function ($scope) {
            $scope.vacancies = {};
            $scope.toggle = toggle;
            $scope.isActiveHideAll = false;
            $scope.hideAll = hideAll;

            function toggle(key){
                $scope.vacancies[key] = !$scope.vacancies[key];
                $scope.isActiveHideAll = hasOpenItems();
            }

            function hasOpenItems(){
                return _
                    .filter($scope.vacancies, filter)
                    .length;

                function filter(item){
                    return item;
                }
            }

            function hideAll(){
                $scope.vacancies = _.mapValues($scope.vacancies, map);
                $scope.isActiveHideAll = false;

                function map(){
                    return false;
                }
            }
        }
    ]);
})(window, window.app, window._, window.debug);
