(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('PaymentCtrl', [
        '$scope',
        '$preloaded',
        'FormHelper',
        'formlyTemplate',
        '$http',
        '$sce',
        '$timeout',
        function ($scope, $preloaded, FormHelper, formlyTemplate, $http, $sce, $timeout) {

            $scope.form = $preloaded.getWithRegExp('paymentForm');
            $scope.form.data = {};
            $scope.form.options.icons = ['_mc', '_visa', '_wm', '_yandex', '_phone', '_sber', '_alfa', '_rouble'];
            $scope.form.options.formId = 'paymentForm';
            $scope.form.options.title = 'Ваши реквизиты:';
            $scope.form.options.submitCopy = 'пополнить баланс';
            $scope.form.options.belowSum = $sce.trustAsHtml($scope.form.options.below_sum);
            formlyTemplate.setTemplateUrl({
                number: 'payment/templates/payment_field-number.html',
                text: 'payment/templates/payment_field-sum.html',
                hidden: 'payment/templates/payment_field-choice.html'
            });

            $scope.form.options.style = {
                title: ['recharge__header-2'],
                item: ['recharge__input-container'],
                submit: ['_fix-width', 'recharge__btn', 'button-pink-big']
            };

            $scope.form.options.show_ua = true;

            $scope.$watch('form.data.sum', function (newValue, oldValue) {
                var bonus_percent = $scope.form.fields[1].bonus_percent;
                if (newValue && bonus_percent) {
                    $scope.form.fields[1].total_sum = Math.round(newValue * (1.0 + parseFloat(bonus_percent) / 100.0));
                } else {
                    $scope.form.fields[1].total_sum = null;
                }
            });

            $scope.$watch('form.data.phone', function(newValue, oldValue) {
                if (newValue && !oldValue) {
                    $scope.loading = true;
                    $http.post($scope.form.options.numberinfo, {phone: newValue}).then(
                        function (response) {
                            if (response.data.name) {
                                $scope.form.data.payment_provider = response.data.name;
                                $scope.form.fields[1].max_value = response.data.max_value;
                                $scope.form.fields[1].min_value = response.data.min_value;
                                $scope.form.fields[1].bonus_percent = response.data.bonus_percent;
                                $scope.form.options.is_pay_available = response.data.is_pay_available;
                                $scope.form.options.payment_provider = response.data.name;
                                $scope.form.options.paymentUnavailable = response.data.name === 'whyfly' && !response.data.is_pay_available;

                                if ($scope.form.options.paymentUnavailable) {
                                    window.alert(
                                        'К сожалению, для вашего номера не доступно пополнение баланса через сайт.'
                                    );
                                }

                                if (!$scope.form.options.is_pay_available) {
                                    $scope.form.options.submitCopy = 'продолжить';
                                    $scope.form.onSubmit = function () {
                                        document.location = response.data.url.replace(
                                            '{{number}}', $scope.form.data.phone
                                        ).replace(
                                            '{{sum}}', $scope.form.data.sum
                                        );
                                    };
                                } else {
                                    $scope.form.onSubmit = available_submit;
                                }
                            } else {
                                window.alert(
                                    'Указан неизвестный номер телефона.'
                                );
                            }
                            $scope.loading = false;
                        },
                        bad_response
                    );
                }
            });

            function bad_response(response) {
                $scope.loading = false;
                window.alert(
                    'На сервере возникла ошибка, ' +
                    'к сожалению, сейчас невозможно ' +
                    'отправить форму.\nПопробуйте ' +
                    'отправить её вновь позднее.'
                );
                return response;
            }

            function available_submit() {
                $scope.loading = true;
                $http.post($scope.form.options.action, $scope.form.data).then(
                    function (response) {
                        $scope.yandex_form = $sce.trustAsHtml(response.data.form);
                        $timeout(function() {
                            document.getElementById('yandex_submit').click();
                        }, 1000);
                    },
                    bad_response
                );
            }

        }]);
})(window, window.app, window._, window.debug);
