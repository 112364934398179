(function (window, app, _, debug, undefined) {
    'use strict';
    app.factory('$preloaded', function () {
        var preloaded = {},
            set = function (name, data) {
            preloaded[name] = data;
        },
            get = function (name) {
            return preloaded[name] || {};
        },
            convertRegExp = function(data, regexpField){
            return _.map(data, function(item){
                if(item[regexpField]){
                    item[regexpField] = new RegExp(item[regexpField]);
                } else{
                    item[regexpField] = new RegExp('');
                }
                return item;
            });
        },
            getWithRegExp = function(name, mapItem, regexpField){
            regexpField = regexpField || 'pattern';
            mapItem = mapItem || 'fields';
            var data = get(name);
            data[mapItem] = convertRegExp(data[mapItem], regexpField);
            return data;
        };
        return {
            set: set,
            get: get,
            getWithRegExp: getWithRegExp
        };
    });
})(window, window.app, window._, window.debug);
