(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('TariffCtrl', [
        '$scope',
        '$preloaded',
        '$location',
        'FiltersHelper',
        'TariffSelectedExchange',
        function ($scope, $preloaded, $location, helper, selected) {
            var filterKey = 'tariff_type';
            $scope.toggleGroupFilterPane = function () {
                if ($scope.groupFilterPane) {
                    _.forEach(selected, function (value, key) {
                        if (key !== filterKey) {
                            delete(selected[key]);
                        }
                    });
                    $scope.groupFilterPane = false;
                } else {
                    $scope.groupFilterPane = true;
                    $scope.selected[filterKey] = '';
                }
            };

            $scope.tariffOptions = $preloaded.get('tariffOptions');
            $scope.checkboxFields = helper.getCheckboxFields($scope.tariffOptions);

            $scope.paneShowed = false;
            $scope.paneShowedCallback = function(show){
                $scope.paneShowed = show;
            };

            $scope.selected = selected;
            $scope.filter = $preloaded.get('tariffFilter');

            _.merge(selected, helper.getValidSelected($location.search(), $scope.checkboxFields));

            $scope.groupFilterPane = !_.isEmpty(_.omit($scope.selected, filterKey));
            $scope.selected[filterKey] = $scope.selected[filterKey] || '';
            $scope.$watch('selected', helper.selectedWatch, true);
        }
    ]);
})(window, window.app, window._, window.debug);
