(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('TariffConnectModalCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        'TariffConnectModal',
        '$preloaded',
        'formlyTemplate',
        'FormHelper',
        function ($scope, $rootScope, $timeout, modal, $preloaded, formlyTemplate, formHelper) {
            $scope.originalForm = $preloaded.getWithRegExp('connectForm');
            $scope.form = angular.copy($scope.originalForm);

            $scope.form.options.title = $scope.form.options.title || $preloaded.get('connectTariffTitle');
            $scope.form.options.description = $preloaded.getWithRegExp('connectTariffDesc');
            $scope.form.options.cardFields = [];

            $scope.form.fields = _.reduce($scope.form.fields, getFieldsSetCards, []);

            $scope.closeModal = modal.deactivate;

            formlyTemplate.setTemplateUrl({
                text: 'wf/tariff_connect_field-text.html',
                email: 'wf/tariff_connect_field-email.html',
                tel: 'wf/tariff_connect_field-tel.html',
                card: 'wf/tariff_connect_field-card.html'
            });

            $scope.form.data = {
                tariff: $preloaded.get('connectTariffId')
            };

            _.map($scope.form.fields, function (item) {
                if (item.value) {
                    $scope.form.data[item.key] = item.value;
                }
            });

            $scope.form.options.style = {
                'form': ['tariff-form'],
                'list': ['form-tariff'],
                'item': ['form-tariff-row3'],
                'itemTitle': ['form-tariff-row-single', 'form-tariff-first'],
                'itemFooter': ['form-tariff-row-single', 'form-tariff-last'],
                'submit': ['form-tariff-submit']
            };
            $scope.form.options.formId = 'connectForm';
            $scope.form.options.submitCopy = 'Подключиться';
            $scope.form.options.showRequire = true;
            $scope.form.options.show_agreement = true;
            $scope.form.onSubmit = formHelper.defaultSubmitAction($scope.form);

            $scope.modalCloseFunc = function () {
                $scope.closeModal();
            };

            $scope.formSendCallback = function (event, args) {
                if (args.formId != $scope.form.options.formId) {
                    return false;
                }
                return $timeout(function () {
                    $scope.modalCloseFunc();
                    $scope.$parent.connectSuccess = true;
                }, 2000);
            };
            $rootScope.$on('formSended', $scope.formSendCallback);

            $scope.getFieldsSetCards = getFieldsSetCards;
            $scope.getClearFunc = getClearFunc;

            function getFieldsSetCards(fields, item) {
                if (item.type === 'card') {
                    item.clear = getClearFunc(item.key);
                    $scope.form.options.cardFields.push(item);
                    return fields;
                } else {
                    fields.push(item);
                    return fields;
                }
            }

            function getClearFunc(current) {
                return clear;

                function clear() {
                    _.each($scope.form.options.cardFields, function(item){
                        if (item.key !== current) {
                            $scope.form.data[item.key] = '';
                            item.shadow = true;
                        } else {
                            item.shadow = false;
                        }
                    });
                }
            }
        }]);
})(window, window.app, window._, window.debug);
