(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('TariffTermCtrl', [
        '$scope',
        '$preloaded',
        'FormHelper',
        function ($scope, $preloaded, formHelper) {
            $scope.form = $preloaded.getWithRegExp('connectForm');

            $scope.form.data = {};
            $scope.form.options.formId = 'connectForm';
            $scope.form.options.showRequire = true;
            $scope.form.options.show_agreement = true;
            $scope.form.options.title = 'Подключайтесь';
            $scope.form.options.submitCopy = 'Подключиться';
            $scope.form.onSubmit = formHelper.defaultSubmitAction($scope.form);
        }]);
})(window, window.app, window._, window.debug);
