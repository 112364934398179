(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('ServiceCtrl', [
        '$scope',
        '$window',
        '$preloaded',
        '$location',
        'matchmedia',
        'FiltersHelper',
        'ServiceSelectedExchange',
        function ($scope, $window, $preloaded, $location, matchmedia, helper, selected) {
            $scope.getFiltred = function(selected){
                return _.isEmpty(selected) ? {filter: ''} : {filter: 'optimize'};
            };
            $scope.showGroupFilterPane = function () {
                $scope.isMobile = !matchmedia.isDesktop();
                $scope.filtred = {filter: 'optimize'};
                $scope.groupFilterPane = true;
            };
            $scope.hideGroupFilterPane = function () {
                $scope.isMobile = !matchmedia.isDesktop();
                $scope.groupFilterPane = false;
                _.each(selected, function (value, key) {
                    delete(selected[key]);
                });
            };
            $scope.toggleGroupFilterPane = function(){
                if($scope.groupFilterPane){
                    $scope.hideGroupFilterPane();
                } else {
                    $scope.showGroupFilterPane();
                }
            };

            $scope.serviceOptions = $preloaded.get('serviceOptions');
            $scope.checkboxFields = helper.getCheckboxFields($scope.serviceOptions);

            $scope.paneShowed = false;
            $scope.paneShowedCallback = function(show){
                $scope.paneShowed = show;
            };

            $scope.selected = selected;
            $scope.filter = $preloaded.get('serviceFilter');

            _.merge(selected, helper.getValidSelected($location.search(), $scope.checkboxFields));

            $scope.filtred = $scope.getFiltred($scope.selected);
            $scope.groupFilterPane = !_.isEmpty($scope.selected);
            $scope.$watch('selected', helper.selectedWatch, true);
        }
    ]);
})(window, window.app, window._, window.debug);
