(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('PartnersCtrl', [
        '$scope',
        '$preloaded',
        'FormHelper',
        function ($scope, $preloaded, formHelper) {
            $scope.form = $preloaded.getWithRegExp('partnersForm');

            $scope.form.data = {};
            $scope.form.options.formId = 'partnersForm';
            $scope.form.options.title = 'Стать партнёром';
            $scope.form.options.submitCopy = 'Стать партнёром';
            $scope.form.options.show_agreement = true;
            $scope.form.options.style = {
                'form': ['partners-form'],
                'list': ['form-partners']
            };
            $scope.form.onSubmit = formHelper.defaultSubmitAction($scope.form);
        }]);
})(window, window.app, window._, window.debug);
