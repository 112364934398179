(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('FeedbackModalCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        'FeedbackModal',
        '$preloaded',
        'formlyTemplate',
        'FormHelper',
        function ($scope, $rootScope, $timeout, modal, $preloaded, formlyTemplate, formHelper) {
            $scope.form = $preloaded.getWithRegExp('feedbackForm');
            $scope.closeModal = modal.deactivate;
            $scope.modalActive = modal.active;

            formlyTemplate.setTemplateUrl({
                text: 'wf/feedback_field-text.html',
                email: 'wf/feedback_field-email.html',
                tel: 'wf/feedback_field-tel.html',
                textarea: 'wf/feedback_field-textarea.html'
            });

            $scope.form.data = {};
            $scope.form.options.formId = 'feedbackForm';
            $scope.form.options.style = {
                'form': ['form-feedback'],
                'list': ['form-feedback'],
                'itemTitle': ['form-feedback-row-single'],
                'itemFooter': ['form-feedback-row-single']
            };
            $scope.form.options.title = 'Обратная связь';
            $scope.form.options.submitCopy = 'Отправить';
            $scope.form.options.showRequire = true;
            $scope.form.options.show_agreement = true;

            $scope.form.onSubmit = formHelper.defaultSubmitAction($scope.form);
            $scope.modalCloseFunc = function(){
                return $scope.closeModal();
            };

            $scope.formSendCallback = function (event, args) {
                if (args.formId != $scope.form.options.formId) {
                    return false;
                }
                return $timeout(function () {
                    $scope.modalCloseFunc();
                }, 2000);
            };
            $rootScope.$on('formSended', $scope.formSendCallback);
        }]);
})(window, window.app, window._, window.debug);
