(function (window, app, debug, undefined) {
    'use strict';
    app.directive('script', ['$preloaded', function ($preloaded) {
        return {
            restrict: 'E',
            link: function (scope, element, attrs) {
                if (attrs.type !== 'text/preloaded') {
                    return;
                }
                var data = {};
                try {
                    data = JSON.parse(element.text());
                } catch (error) {
                    data = {};
                }
                if (attrs.hasOwnProperty('name')) {
                    $preloaded.set(attrs.name, data);
                }
            }
        };
    }]);
})(window, window.app, window.debug);
