(function (window, app, _, debug, undefined) {
    'use strict';
    app.controller('MainIndexCtrl', [
        '$scope',
        '$location',
        '$document',
        'FiltersHelper',
        'TariffSelectedExchange',
        'ServiceSelectedExchange',
        function ($scope, $location, $document, helper, tariffSelected, serviceSelected) {
            var tariffFilterKey = 'tariff_type';
            $scope.isEmpty = function(obj){
                if(_.isPlainObject(obj)){
                    return _.isEmpty(_.omit(obj, tariffFilterKey));
                } else {
                    return _.isEmpty(obj);
                }
            };

            $scope.tariffSelected = tariffSelected;
            $scope.tariffSelected[tariffFilterKey] = '';
            $scope.serviceSelected = serviceSelected;
            $scope.getQueryString = helper.getQueryString;
            $scope.setActive = setActive;

            $scope.blocks = {
                tariff: true,
                service: false,
                partner: false
            };

            function setActive(block){
                $scope.blocks = _.mapValues($scope.blocks, map);

                function map(value, key){
                    return key == block;
                }
            }
        }
    ]);
})(window, window.app, window._, window.debug);
